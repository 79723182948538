import gql from 'graphql-tag';
import { CASE_FRAGMENT } from '../cm';
import { PROFILE_FRAGMENT } from '../profiles/profiles';
import { FORM_CONFIGURATION_FRAGMENT } from '../submission/FormConfiguration';
import { SUBMISSION_FRAGMENT } from '../submission';

export const EDD_REPORT_FRAGMENT = gql`
  ${CASE_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${FORM_CONFIGURATION_FRAGMENT}
  ${SUBMISSION_FRAGMENT}
  fragment EDDReportFragment on EDDReport {
    id
    assigned_to @type(name: "Account") {
      id
      avatar_id
      email
      file_name
      file_size
      full_name
      phone
    }
    assigned_to_id
    case_ids
    cases @type(name: "Case") {
      ...CaseFragment
    }
    client_id
    completed_at
    created_at
    description
    edd_report_documents @type(name: "EDDReportDocument") {
      id
      edd_report_id
      created_at
      update_at
      description
      uploaded_document_id
    }
    form_configuration @type(name: "FormConfiguration") {
      ...FormConfigurationFragment
    }
    form_configuration_id
    form_submission @type(name: "Submission") {
      ...SubmissionFragment
      field_submissions @type(name: "FieldSubmission") {
        business_passport_id
        created_at
        file_name
        file_size
        field_update_claims_count
        form_field @type(name: "FormField") {
          created_at
          description
          field_template @type(name: "FormFieldTemplate") {
            code
            common
            created_at
            defaults
            field_select_values
            id
            kind
            name
            options
            updated_at
          }
          form_group @type(name: "FormGroup") {
            created_at
            form_screen_id
            id
            name
            options
            position
            repeatable
            tags
            updated_at
          }
          field_template_id
          id
          name
          options
          position
          protected_tags
          reference_key
          tags
          updated_at
        }
        form_group_index
        form_group_subindex
        form_invitation_id
        id
        personal_passport_id
        updated_at
        uploaded_document_id
        value
      }
    }
    form_submission_id
    main_profile @type(name: "Profile") {
      id
      avatar_id
      created_at
      display_name
      phone
      email
      kind
    }
    profiles @type(name: "Profile") {
      avatar_id
      created_at
      display_name
      email
      id
      is_whitelisted
      kind
      phone
      updated_at
    }
    main_profile_id
    profile_kind
    status
    updated_at
  }
`;
